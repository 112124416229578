<template>
  <div class="row justify-center">
    <q-card style="margin-bottom: 100px;">
        <Map @map-change="onChange" @map-end-change='mapEndChange' @map-start-change='mapStartChange' />
        <q-inner-loading :showing="isLoading"  >
        </q-inner-loading>
    </q-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Map from "@/components/Map.vue";

export default {
  name: "LandMark",
  components: {
    Map: Map
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    onChange(data) {
      this.$router.push({ name: "MarkList", params: { id: data.id } });
    },
    mapEndChange () {
      this.isLoading = false
    },
    mapStartChange () {
      this.isLoading = true
    }
  }
};
</script>
