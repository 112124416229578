// const markPointData = [
//   {
//     name: "阿勒泰市",
//     coord: [88.13913, 47.8317],
//     symbolSize: 20,
//     id: 0
//   },
//   {
//     name: "布尔津",
//     coord: [86.85751, 47.70062],
//     symbolSize: 20,
//     id: 1
//   },
//   {
//     name: "福海",
//     coord: [87.49508, 47.11065],
//     symbolSize: 20,
//     id: 2
//   },
//   {
//     name: "清河",
//     coord: [90.38305, 46.67382],
//     symbolSize: 20,
//     id: 3
//   },
//   {
//     name: "裕民",
//     coord: [82.99002, 46.20377],
//     symbolSize: 20,
//     id: 4
//   },
//   {
//     name: "精河",
//     coord: [82.89419, 44.60774],
//     symbolSize: 20,
//     id: 5
//   },
//   {
//     name: "沙湾",
//     coord: [85.61932, 44.33144],
//     symbolSize: 20,
//     id: 6
//   },
//   {
//     name: "呼图壁",
//     coord: [86.89892, 44.18977],
//     symbolSize: 20,
//     id: 7
//   },
//   {
//     name: "阜康",
//     coord: [87.98529, 44.1584],
//     symbolSize: 20,
//     id: 8
//   },
//   {
//     name: "昌吉",
//     coord: [87.30249, 44.01267],
//     symbolSize: 20,
//     id: 9
//   },
//   {
//     name: "奇台",
//     coord: [89.5932, 44.02221],
//     symbolSize: 20,
//     id: 10
//   },
//   {
//     name: "木垒",
//     coord: [90.28897, 43.83508],
//     symbolSize: 20,
//     id: 11
//   },
//   {
//     name: "伊犁",
//     coord: [81.317946, 43.92186],
//     symbolSize: 20,
//     id: 12
//   },
//   {
//     name: "新源",
//     coord: [83.26095, 43.4284],
//     symbolSize: 20,
//     id: 13
//   },
//   {
//     name: "昭苏",
//     coord: [81.1307, 43.15828],
//     symbolSize: 20,
//     id: 14
//   },
//   {
//     name: "特克斯",
//     coord: [81.84005, 43.21938],
//     symbolSize: 20,
//     id: 15
//   },
//   {
//     name: "尼勒克",
//     coord: [82.51184, 43.79901],
//     symbolSize: 20,
//     id: 16
//   },
//   {
//     name: "库尔勒",
//     coord: [86.15528, 41.76602],
//     symbolSize: 20,
//     id: 17
//   },
//   {
//     name: "尉犁",
//     coord: [86.25903, 41.33632],
//     symbolSize: 20,
//     id: 18
//   },
//   {
//     name: "若羌",
//     coord: [88.16812, 39.0179],
//     symbolSize: 20,
//     id: 19
//   },
//   {
//     name: "和静",
//     coord: [86.39611, 42.31838],
//     symbolSize: 20,
//     id: 20
//   },
//   {
//     name: "和硕",
//     coord: [86.86392, 42.26814],
//     symbolSize: 20,
//     id: 21
//   },
//   {
//     name: "博湖",
//     coord: [86.63333, 41.98014],
//     symbolSize: 20,
//     id: 22
//   },
//   {
//     name: "吐鲁番",
//     coord: [89.18579, 42.93505],
//     symbolSize: 20,
//     id: 23
//   },
//   {
//     name: "托克逊",
//     coord: [88.65823, 42.79231],
//     symbolSize: 20,
//     id: 24
//   },
//   {
//     name: "伊州",
//     coord: [93.51452, 42.82699],
//     symbolSize: 20,
//     id: 25
//   },
//   {
//     name: "阿克苏",
//     coord: [80.26338, 41.16754],
//     symbolSize: 20,
//     id: 26
//   },
//   {
//     name: "库车",
//     coord: [82.96209, 41.71793],
//     symbolSize: 20,
//     id: 27
//   },
//   {
//     name: "沙雅",
//     coord: [82.78131, 41.22497],
//     symbolSize: 20,
//     id: 28
//   },
//   {
//     name: "新和",
//     coord: [82.61053, 41.54964],
//     symbolSize: 20,
//     id: 29
//   },
//   {
//     name: "喀什市",
//     coord: [75.99379, 39.46768],
//     symbolSize: 20,
//     id: 30
//   },
//   {
//     name: "叶城",
//     coord: [77.41659, 37.88324],
//     symbolSize: 20,
//     id: 31
//   },
//   {
//     name: "麦盖提",
//     coord: [77.64224, 38.89662],
//     symbolSize: 20,
//     id: 32
//   },
//   {
//     name: "伽师",
//     coord: [76.72372, 39.48801],
//     symbolSize: 20,
//     id: 33
//   },
//   {
//     name: "塔什库尔干",
//     coord: [75.23196, 37.77893],
//     symbolSize: 20,
//     id: 34
//   },
//   {
//     name: "和田市",
//     coord: [79.91353, 37.11214],
//     symbolSize: 20,
//     id: 35
//   },
//   {
//     name: "皮山",
//     coord: [78.28125, 37.62007],
//     symbolSize: 20,
//     id: 36
//   },
//   {
//     name: "策勒",
//     coord: [80.80999, 36.99843],
//     symbolSize: 20,
//     id: 37
//   },
//   {
//     name: "于田",
//     coord: [81.66717, 36.854],
//     symbolSize: 20,
//     id: 38
//   },
//   {
//     name: "民丰",
//     coord: [82.68444, 37.06577],
//     symbolSize: 20,
//     id: 39
//   },
//   {
//     name: "阿图什",
//     coord: [76.16839, 39.7161],
//     symbolSize: 20,
//     id: 40
//   },
//   {
//     name: "巴音州",
//     coord: [86.57425, 42.0591],
//     symbolSize: 20,
//     id: 41
//   },
//   {
//     name: "博乐",
//     coord: [82.05112, 44.85387],
//     symbolSize: 20,
//     id: 42
//   },
//   {
//     name: "察布查尔",
//     coord: [82.05112, 44.85387],
//     symbolSize: 20,
//     id: 43
//   },
//   {
//     name: "巩留",
//     coord: [82.23175, 43.48257],
//     symbolSize: 20,
//     id: 44
//   },
//   {
//     name: "霍城",
//     coord: [82.23175, 43.48257],
//     symbolSize: 20,
//     id: 45
//   },
//   {
//     name: "玛纳斯",
//     coord: [86.21421, 44.30413],
//     symbolSize: 20,
//     id: 46
//   },
//   {
//     name: "吉木萨尔",
//     coord: [89.18076, 44.00061],
//     symbolSize: 20,
//     id: 47
//   },
//   // {
//   //   name: "莫力达瓦旗",
//   //   coord: [124.51936, 48.47711],
//   //   symbolSize: 20,
//   //   id: 48
//   // },
//   {
//     name: "莎车",
//     coord: [77.24629, 38.41432],
//     symbolSize: 20,
//     id: 49
//   },
//   {
//     name: "头屯河区",
//     coord: [87.42812, 43.87711],
//     symbolSize: 20,
//     id: 50
//   },
//   {
//     name: "伊吾",
//     coord: [94.69741, 43.25451],
//     symbolSize: 20,
//     id: 51
//   },
//   {
//     name: "英吉沙",
//     coord: [76.17561, 38.92956],
//     symbolSize: 20,
//     id: 52
//   }
// ];

export const mapOption = {
  basicOption: {
    title: {
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}'
    },
    geo: {
      top: 100,
    }
  },
  seriesOption: {
    left: 50,
    top: 30,
    right: 50,
    bottom: 0,
    type: 'map',
    roam: false,
    nameMap: {
      'china': '中国'
    },
    markPoint: {
      label: {
        normal: {
          show: true
        },
        emphasis: {
          show: true
        }
      },
      itemStyle: {
        normal: {
          color: "red"
        },
        emphasis: {
          color: "yellow"
        }
      },
      data: []
    },
    label: {
      distance: 5,
      normal: {
        show: true,
        textStyle: {
          color: '#fff',
        }
      },
      emphasis: {
        show: true,
        textStyle: {
          color: '#fff',
        }
      }
    },
    itemStyle: {
      normal: {
        color: '#fff',
        areaColor: '#4682B4',
        borderColor: '#111',
        borderWidth: 0.5,
        label: {
          show: true,
          textStyle: {
            color: "rgb(249, 249, 249)",
          }
        },
        opacity: 0.6
      },
      emphasis: {
        areaColor: "#5f9ea0"
      }
    },
  }
}
