<template>
  <div>
    <q-btn class="b-btn" type="primary" @click="back" v-if="deepTree.length > 1">返回</q-btn>
    <div id="map" style="background: #f3f3f3; width: 56rem; height: 40rem"></div>
  </div>
</template>

<script>
import { getChinaJson, getProvinceJSON } from "../api/get-json.js";
import { cityProvincesMap } from "../config/cityProvincesMap";
import { mapOption } from "../config/mapOption";

export default {
  name: "china",
  data() {
    return {
      chart: null, // 实例化echarts
      provincesMap: cityProvincesMap.provincesMap, // 省拼音，用于查找对应json
      provincesCode: cityProvincesMap.provincesCode, // 市行政区划，用于查找对应json
      areaMap: cityProvincesMap.areaMap, // 省行政区划，用于数据的查找，按行政区划查数据
      special: ["北京市", "天津市", "上海市", "重庆市", "香港", "澳门"], //直辖市和特别行政区-只有二级地图，没有三级地图
      mapData: [], // 当前地图上的地区
      option: { ...mapOption.basicOption }, // map的相关配置
      deepTree: [], // 点击地图时push，点返回时pop
      areaName: "中国", // 当前地名
      areaCode: "100000", // 当前行政区划
      areaLevel: "country" // 当前级别
    };
  },
  mounted() {
    //地图尺寸
    // this.windowResize();
    // console.log(this.windowResize);
    // window.addEventListener("resize", this.windowResize, false);
    this.$nextTick(() => {
      this.initEcharts();
      this.chart.on("click", this.echartsMapClick);
    });
  },
  methods: {
    windowResize() {
      let winWheight = window.outerWidth;
      document.querySelector("html").style.fontSize = winWheight / 20 + "px";
    },
    // 初次加载绘制地图
    initEcharts() {
      //地图容器
      this.chart = this.$echarts.init(document.getElementById("map"));
      if (this.areaCode === "100000") {
        this.requestGetChinaJson();
      } else {
        this.requestGetProvinceJSON({
          areaName: this.areaName,
          areaCode: this.areaCode
        });
      }
    },
    // 地图点击
    echartsMapClick(params) {
      if (params.componentType === "markPoint") {
        if (params.data.id.indexOf('0000') === -1) {
          this.$emit("map-change", params.data);
        } else {
          let obj = { areaLevel: 'province', areaCode: params.data.id, areaName: params.data.name, name: params.data.name}
          this.areaCode = obj.areaCode;
          this.areaLevel = obj.areaLevel;
          //如果点击的是34个省、市、自治区，绘制选中地区的二级地图
          this.requestGetProvinceJSON(obj);
          return
        }
      }
      this.areaName = params.areaName;
      if (params.name in this.provincesMap) {
        this.areaCode = params.data.areaCode;
        this.areaLevel = params.data.areaLevel;
        //如果点击的是34个省、市、自治区，绘制选中地区的二级地图
        this.requestGetProvinceJSON(params.data);
      } else if (params.seriesName in this.provincesMap) {
        //如果是【直辖市/特别行政区】只有二级下钻
        if (this.special.indexOf(params.seriesName) >= 0) {
          return;
        } else {
          this.areaCode = this.areaMap[params.name];
          this.areaLevel = params.data.areaLevel;
          //显示县级地图
          // this.requestGetCityJSON(params.data);
        }
      } else {
        return;
      }
      // this.$emit("map-change", params.data);
    },
    //绘制全国地图
    requestGetChinaJson() {
      getChinaJson().then(res => {
        console.log("get china Json res=>",res)
        let arr = [];
        for (let i = 0; i < res.features.length; i++) {
          let obj = {
            name: res.features[i].properties.name,
            areaName: res.features[i].properties.name,
            areaCode: res.features[i].id,
            areaLevel: "province",
            value: Math.round(Math.random())
          };
          arr.push(obj);
        }
        this.mapData = arr;
        this.deepTree.push({
          mapData: arr,
          params: {
            name: "china",
            areaName: "china",
            areaLevel: "country",
            areaCode: "100000"
          }
        });
        // 注册地图
        this.$echarts.registerMap("china", res);
        // 绘制地图
        // this.renderMap("china", arr);
        // console.log("requestGetChinaJson", arr);
      });
    },
    // 加载省级地图
    requestGetProvinceJSON(params) {
      getProvinceJSON(params.areaCode).then(res => {
        this.$echarts.registerMap(params.areaName, res);
        let arr = [];
        for (let i = 0; i < res.features.length; i++) {
          let obj = {
            name: res.features[i].properties.name,
            areaName: res.features[i].properties.name,
            areaCode: res.features[i].id,
            areaLevel: "city",
            value: Math.round(Math.random())
          };
          arr.push(obj);
        }
        this.mapData = arr;
        this.deepTree.push({
          mapData: arr,
          params: params
        });
      });
    },
    // 加载市级地图
    // requestGetCityJSON(params) {
    //   this.areaLevel = params.areaLevel;
    //   getCityJSON(params.areaCode).then(res => {
    //     // console.log("加载市级", res);
    //     this.$echarts.registerMap(params.areaName, res);
    //     let arr = [];
    //     for (let i = 0; i < res.features.length; i++) {
    //       let obj = {
    //         name: res.features[i].properties.name,
    //         areaName: res.features[i].properties.areaName,
    //         areaCode: res.features[i].id,
    //         areaLevel: "districts",
    //         value: Math.round(Math.random())
    //       };
    //       arr.push(obj);
    //     }
    //     this.mapData = arr;
    //     this.deepTree.push({ mapData: arr, params: params });
    //     // this.renderMap(params.areaName, arr);
    //   });
    // },
    // 根据配置, 显示地图
    renderMap(item, option) {
      this.option.series = [
        {
          name: item.params.name,
          mapType: item.params.name,
          ...option,
          data: item.mapData
        }
      ];
      console.log('option',this.option)
      //渲染地图
      this.chart.setOption(this.option);
      let myChat = this.chart;
      window.addEventListener("resize", function() {
        myChat.resize();
      });
    },
    // 返回
    back() {
      // console.log(this.deepTree);
      if (this.deepTree.length > 1) {
        this.deepTree.pop();
        this.$emit(
          "back-change",
          this.deepTree[this.deepTree.length - 1].params
        );
      }
    }
  },
  watch: {
    deepTree: {
      handler: function (val, old) {
        this.$emit('map-start-change')
        if (!old) {
          getChinaJson().then(res => {
            let arr = [];
            for (let i = 0; i < res.features.length; i++) {
              let obj = {
                name: res.features[i].properties.name,
                areaName: res.features[i].properties.name,
                areaCode: res.features[i].id,
                areaLevel: "province",
                value: Math.round(Math.random())
              };
              arr.push(obj);
            }
          this.mapData = arr;
          this.renderMap({ params: { name: 'china', mapData: arr } }, mapOption.seriesOption)
      });
        }
          // console.log(old, val)
        if (val.length !== 0) {
          let item = val[val.length - 1]
          let seriesOption = mapOption.seriesOption
          this.axios.get(process.env.VUE_APP_API_BASE_URL+'/landmark/?proid=' + item.params.areaCode)
            .then(res => {
              if (res.data.code === 20000) {
                let new_data = res.data.data
                for (let i = 0; i < new_data.length; i++) {
                  new_data[i].coord = [new_data[i].coordinate.x, new_data[i].coordinate.y]
                  new_data[i].symbolSize = 20
                }
                seriesOption.markPoint.data = new_data
              } else {
                seriesOption.markPoint.data = []
              }
              this.renderMap(item, seriesOption)
              this.$emit('map-end-change')
            })
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
html {
  font-size: 96px;
}
.b-btn {
  position: absolute;
  z-index: 9999;
  left: 1.8rem;
  top: 1.8rem;
}
</style>
